var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vs-table",
    {
      attrs: {
        noDataText: "No hay ordenes de compras",
        data: _vm.renderPurchaseOrders,
      },
      scopedSlots: _vm._u([
        {
          key: "default",
          fn: function ({ data }) {
            return _vm._l(data, function (tr, indextr) {
              return _c(
                "vs-tr",
                { key: indextr },
                [
                  _c("vs-td", [
                    _c(
                      "a",
                      {
                        staticStyle: { "padding-left": "10px" },
                        attrs: { href: tr.file, target: "_blank" },
                      },
                      [_vm._v("\n              Ver\n            ")]
                    ),
                  ]),
                  _c(
                    "vs-td",
                    [
                      _c(
                        "vs-chip",
                        {
                          staticClass: "w-full",
                          attrs: {
                            transparent: "",
                            color: tr.canceled_at ? "danger" : "success",
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(tr.canceled_at ? "Anulada" : "Activa") +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("vs-td", { class: { "opacity-50": tr.canceled_at } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          tr.created_at ? _vm.dateFormatter(tr.created_at) : "-"
                        ) +
                        "\n          "
                    ),
                  ]),
                  _c("vs-td", { class: { "opacity-50": tr.canceled_at } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(tr.year ? tr.year : "-") +
                        "\n          "
                    ),
                  ]),
                  _c("vs-td", { class: { "opacity-50": tr.canceled_at } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          tr.month + 1 ? _vm.monthFormatter(tr.month) : "-"
                        ) +
                        "\n          "
                    ),
                  ]),
                  _c(
                    "vs-td",
                    {
                      staticClass: "total-currency-column",
                      attrs: { id: `total-currency-column-${tr.id}` },
                    },
                    [
                      _c(
                        "vs-select",
                        {
                          attrs: { value: tr.currency },
                          on: {
                            input: function ($event) {
                              return _vm.handleCurrencyEdit(tr.id, $event)
                            },
                          },
                        },
                        [
                          _vm._l(_vm.currencies, function (currency) {
                            return _c("vs-select-item", {
                              key: currency.value,
                              attrs: {
                                value: currency.value,
                                text: currency.text,
                              },
                            })
                          }),
                          _c(
                            "span",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [_vm._v("Nada que mostrar.")]
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    {
                      staticClass: "editable-column total-amount-column",
                      attrs: { id: `total-amount-column-${tr.id}` },
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "amount-container",
                          class: { "opacity-50": tr.canceled_at },
                        },
                        [
                          _vm._v(
                            " \n              " +
                              _vm._s(
                                tr.total_amount
                                  ? _vm.stringCurrencyFormatter(tr.total_amount)
                                  : "-"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                      _c("vs-icon", {
                        staticClass: "cursor-pointer",
                        attrs: {
                          title: "Editar monto",
                          icon: "edit",
                          color: tr.canceled_at ? "lightgray" : "gray",
                        },
                        on: {
                          click: function ($event) {
                            tr.canceled_at ? null : _vm.toggleAmountEdit(tr.id)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "vs-td",
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "w-full",
                          attrs: {
                            color: tr.invoiced_at ? "success" : "danger",
                            size: "small",
                            disabled: tr.canceled_at || tr.month === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleInvoiced(tr.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                tr.invoiced_at ? "Facturado" : "No facturado"
                              ) +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("vs-td", { class: { "opacity-50": tr.canceled_at } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          tr.invoiced_at
                            ? _vm.dateFormatter(tr.invoiced_at)
                            : "-"
                        ) +
                        "\n          "
                    ),
                  ]),
                  _c(
                    "vs-td",
                    [
                      _c(
                        "vs-button",
                        {
                          staticClass: "w-full",
                          attrs: {
                            color: tr.paid_at ? "success" : "danger",
                            size: "small",
                            disabled: tr.canceled_at || tr.month === 0,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleCollected(tr.id)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(tr.paid_at ? "Pagado" : "No pagado") +
                              "\n            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _c("vs-td", { class: { "opacity-50": tr.canceled_at } }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(
                          tr.paid_at ? _vm.dateFormatter(tr.paid_at) : "-"
                        ) +
                        "\n          "
                    ),
                  ]),
                  _c("vs-td", [
                    _c(
                      "div",
                      { staticClass: "flex justify-start gap-2" },
                      [
                        _c("vs-button", {
                          attrs: {
                            color: tr.canceled_at ? "warning" : "danger",
                            title: tr.canceled_at ? "Recuperar" : "Anular",
                            type: tr.canceled_at ? "filled" : "border",
                            icon: tr.canceled_at ? "restore" : "not_interested",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleCanceled(tr.id)
                            },
                          },
                        }),
                        _c("vs-button", {
                          attrs: {
                            color: "primary",
                            type: "filled",
                            title: "Reemplazar",
                            icon: "repeat",
                            disabled: tr.canceled_at,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.openReplaceOdcInput(tr)
                            },
                          },
                        }),
                        _c("input", {
                          staticClass: "hidden",
                          attrs: {
                            type: "file",
                            name: `replaceOdcInput-${tr.id}`,
                            id: `replaceOdcInput-${tr.id}`,
                            accept: "application/pdf",
                          },
                          on: { change: _vm.handleReplaceOdcUpload },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              )
            })
          },
        },
      ]),
    },
    [
      _c(
        "template",
        { slot: "thead" },
        [
          _c("vs-th", [_vm._v("Archivo")]),
          _c("vs-th", { attrs: { width: "1000px" } }, [
            _vm._v("Estado de la ODC"),
          ]),
          _c("vs-th", { attrs: { width: "1000px" } }, [
            _vm._v("Fecha de subida ODC"),
          ]),
          _c("vs-th", [_vm._v("Año")]),
          _c("vs-th", { attrs: { width: "500px" } }, [_vm._v("Mes")]),
          _c("vs-th", { attrs: { width: "500px" } }, [_vm._v("Moneda")]),
          _c("vs-th", { attrs: { width: "1000px" } }, [_vm._v("Monto")]),
          _c("vs-th", { attrs: { width: "1000px" } }, [_vm._v("Facturado")]),
          _c("vs-th", { attrs: { width: "1000px" } }, [
            _vm._v("Fecha de facturación"),
          ]),
          _c("vs-th", { attrs: { width: "1000px" } }, [_vm._v("Pagado")]),
          _c("vs-th", { attrs: { width: "1000px" } }, [
            _vm._v("Fecha de pago"),
          ]),
          _c("vs-th", [_vm._v("Acción")]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }