<template>
    <vs-table noDataText="No data" :data="tableData.purchaseOrder">
        <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th width="1000px">Fecha de subida ODC</vs-th>
            <vs-th>Año</vs-th>
            <vs-th width="500px">Mes</vs-th>
            <vs-th width="1000px">Monto</vs-th>

            <!-- !!!!! When exist all fields this code will be shown !!!!!! -->

            <vs-th>Facturado</vs-th>
            <vs-th>Archivo Factura</vs-th>
            <vs-th width="1000px">Fecha subida Factura</vs-th>
            <vs-th v-if="variant === 'billing'">Cobrado</vs-th>
            <vs-th width="1000px" v-if="variant === 'billing'">Fecha de cobro</vs-th>
            <vs-th v-if="variant === 'payment'">Pagado</vs-th>
            <vs-th width="1000px" v-if="variant === 'payment'">Fecha de pago</vs-th>
            <vs-th>Acción</vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                  Ver
                </a>  
              </vs-td>
              <vs-td>
                {{ tr.created_at ? dateFormatter(tr.created_at) : '-' }}
              </vs-td>
              <vs-td>
                {{ tr.year ? tr.year : '-' }}
              </vs-td>
              <vs-td>
                {{ tr.month + 1 ? monthFormatter(tr.month) : '-' }}
              </vs-td>
              <vs-td>
                {{ tr.total_amount ? stringCurrencyFormatter(tr.total_amount) : '-' }}
              </vs-td>
              <!-- !!!!! When exist all fields this code will be shown !!!!! -->
              <vs-td>
                <vs-checkbox 
                  color="rgb(38, 187, 174)" 
                  v-model="tr.billed"
                  :title="tr.billed ? 'Facturado' : 'Marcar como facturado'"
                  disabled
                />
              </vs-td>
              <vs-td>
                <template v-if="tr.billed_file">
                  <a style="padding-left: 10px;" :href="tr.billed_file" target="_blank">
                    Ver
                  </a>
                </template>
                <template v-else>
                  -
                </template>
              </vs-td>
              <vs-td>
                {{ tr.billed_at ? dateFormatter(tr.billed_at) : '-' }}
              </vs-td>
              <vs-td v-if="variant === 'billing'"> 
                <vs-checkbox 
                  color="rgb(38, 187, 174)" 
                  v-model="tr.charged"
                  :title="tr.charged ? 'Cobrado' : 'Marcar como cobrado'"
                  disabled
                />
              </vs-td>
              <vs-td v-if="variant === 'billing'">
                {{ tr.charged_at ? dateFormatter(tr.charged_at) : '-' }}
              </vs-td>
              <vs-td v-if="variant === 'payment'">
                <vs-checkbox 
                  color="rgb(38, 187, 174)" 
                  v-model="tr.payed"
                  :title="tr.payed ? 'Pagado' : 'Marcar como pagado'"
                  disabled
                />
              </vs-td>
              <vs-td v-if="variant === 'payment'">
                {{ tr.payed_at ? dateFormatter(tr.payed_at) : '-' }}
              </vs-td>
              <vs-td>
                <div class="flex justify-start gap-2">
                  <!-- <vs-button 
                    v-if="variant === 'billing'"
                    :color="tr.null ? 'dark' : 'danger'" 
                    type="border" 
                    :title="tr.null ? 'Desanular' : 'Anular'" 
                    :icon="tr.null ? 'unarchive' : 'archive'" 
                    @click="markAsNull(tr.id)" 
                  /> -->
                  <vs-button 
                    color="primary" 
                    type="filled" 
                    title="Reemplazar" 
                    icon="repeat" 
                    @click="openReplaceOdcInput(tr)" 
                  />
                  <input 
                    type="file" 
                    :name="`replaceOdcInput-${tr.id}`" 
                    :id="`replaceOdcInput-${tr.id}`" 
                    class="hidden"
                    @change="handleReplaceOdcUpload"
                  >
                </div>
              </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>

<script>
import { dateFormatter, monthFormatter } from '../../../utils/strings';
import { stringCurrencyFormatter } from '../../../utils/strings';
import appConfig from '@/../appConfig.js'

export default {
    name: 'files-table',
    props: {
        tableData: {
            type: Object,
            default: null
        },
        variant: {
            type: String,
            default: 'billing'
        }
    },
    data() {
        return {
            selectedFile: {
              id: null,
              file: null,
              month: null,
              year: null,
              total_amount: null
            },
            dateFormatter,
            monthFormatter,
            stringCurrencyFormatter
        }
    },
    methods: {
      openReplaceOdcInput(file) {
        this.selectedFile = file;
        console.log(file)
        document.getElementById(`replaceOdcInput-${file.id}`).click();
      },
      handleReplaceOdcUpload(event) {
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== 'application/pdf' || file.size > 2048 * 1000) {
          this.$vs.notify({
              title: 'Error',
              text: 'Solo se permiten archivos PDF menores a 2MB',
              color: 'danger'
          });
          event.target.value = ''; // Reset input
          return;
        }
        // Show loading notification
        this.$vs.loading({
          text: 'Subiendo archivo...',
        });

        const formData = new FormData();
        formData.append('file', file);
        formData.append('year', this.selectedFile.year);
        formData.append('month', this.selectedFile.month);
        formData.append('total', this.selectedFile.total_amount); // get total

        this.$http.post(`${appConfig.apiUrl}/purchase-orders/${this.selectedFile.id}`, formData)
        .then((response) => {
          this.$emit('successFileUpdate', file, response);
        })
        .catch((error) => {
          console.error(error);
          this.$vs.loading.close();
          this.$vs.notify({
              title: 'Error',
              text: error.message,
              color: 'danger'
          });
          event.target.value = ''; // Reset input
          return;
        })
        .finally(() => {

          this.selectedFile = {
            id: null,
            file: null,
            month: null,
            year: null,
            total: null
          };
          this.$vs.loading.close();
        });
      },
      
        // markAsNull(id) {
        //     this.$emit('markAsNull', id);
        // }
    }
}
</script>