<template>
    <div class="flex items-center h-full">
        <vs-chip 
          :color="chipSettings.color"
          :transparent="chipSettings.transparent"
          size="small"
          class="h-4"
        > {{ chipSettings.text }} {{checkAmount}} / {{totalAmount}}</vs-chip>
      </div>
  </template>
  
  <script>
  import Vue from 'vue'
  
  export default Vue.extend({
    data () {
      return {
        totalAmount: 0,
        checkAmount: 0,
        chipSettings: {
          transparent: true,
          color: 'dark',
          text: '-'
        }
      }
    },
    beforeMount () {
      const {variant} = this.params
      //Variants now are billing and charges, add payment
      if(variant === 'billing'){
        this.chipSettings.text = 'Facturado'
      }else if(variant === 'charged'){
        this.chipSettings.text = 'Cobrado'
      }else if(variant === 'payed'){
        this.chipSettings.text = 'Pagado'
      }
      
      const cellValue = this.params.value
      const {files} = cellValue
      this.totalAmount = files.length
      this.checkAmount = files.filter(file => variant === 'billing' ? file.billed : variant === 'charged' ? file.charged : variant === 'payed' ? file.payed : false).length

      this.setChipColor()

    },
    methods: {
      setChipColor(){
        if(this.totalAmount === 0){
          this.chipSettings.color = 'dark'
        }else{
          this.chipSettings.transparent = false

          if (this.checkAmount === 0) {
            this.chipSettings.color = 'danger'
          }else{
            if(this.checkAmount === this.totalAmount){
              this.chipSettings.color = 'success'
            }
            if(this.checkAmount < this.totalAmount){
              this.chipSettings.color = 'warning'
            }
          }
        }
      }
    }
  })
  </script>