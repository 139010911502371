<template>
  <div class="flex items-center h-full">
      <vs-chip 
        :color="chipSettings.color"
        :transparent="chipSettings.transparent"
        size="small"
        class="h-4"
        style="width: 100%;"
      > {{ chipSettings.text }} {{checkAmount}} / {{totalAmount}}</vs-chip>
    </div>
</template>

<script>
import Vue from 'vue'
import { PROVISIONAL_MONTH } from '../../utils/strings'

export default Vue.extend({
  data () {
    return {
      totalAmount: 0,
      checkAmount: 0,
      chipSettings: {
        transparent: true,
        color: 'dark',
        text: '-'
      }
    }
  },
  beforeMount () {
    const {variant} = this.params
    //Variants now are billing and charges, add payment
    if(variant === 'invoiced'){
      this.chipSettings.text = 'Facturado'
    }else if(variant === 'collected'){
      this.chipSettings.text = 'Cobrado'
    }else if(variant === 'payed'){
      this.chipSettings.text = 'Pagado'
    }
    
    const cellValue = this.params.value
    const {files, is_programmatic} = cellValue
    let filteredFiles = files.filter(file => !file.canceled_at)
    
    if(is_programmatic){
      filteredFiles = filteredFiles.filter(file => file.month !== PROVISIONAL_MONTH)
    }

    this.totalAmount = filteredFiles.length
    this.checkAmount = filteredFiles.filter(file => 
      variant === 'invoiced' ? file.invoiced_at 
      : variant === 'collected' ? file.paid_at 
      : variant === 'payed' ? file.paid_at : false).length

    this.setChipColor()

  },
  methods: {
    setChipColor(){
      if(this.totalAmount === 0){
        this.chipSettings.color = 'dark'
      }else{
        this.chipSettings.transparent = false

        if (this.checkAmount === 0) {
          this.chipSettings.color = 'danger'
        }else{
          if(this.checkAmount === this.totalAmount){
            this.chipSettings.color = 'success'
          }
          if(this.checkAmount < this.totalAmount){
            this.chipSettings.color = 'warning'
          }
        }
      }
    }
  }
})
</script>