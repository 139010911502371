var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "flex items-center h-full" },
    [
      _c(
        "vs-chip",
        {
          staticClass: "h-4",
          staticStyle: { width: "100%" },
          attrs: {
            color: _vm.chipSettings.color,
            transparent: _vm.chipSettings.transparent,
            size: "small",
          },
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.chipSettings.text) +
              " " +
              _vm._s(_vm.checkAmount) +
              " / " +
              _vm._s(_vm.totalAmount)
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }