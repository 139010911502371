<template>
    <vs-table noDataText="No hay ordenes de compras" :data="renderPurchaseOrders">
        <template slot="thead">
            <vs-th>Archivo</vs-th>
            <vs-th width="1000px">Estado de la ODC</vs-th>
            <vs-th width="1000px">Fecha de subida ODC</vs-th>
            <vs-th>Año</vs-th>
            <vs-th width="500px">Mes</vs-th>
            <vs-th width="500px">Moneda</vs-th>
            <vs-th width="1000px">Monto</vs-th>
            <vs-th width="1000px">Facturado</vs-th>
            <vs-th width="1000px">Fecha de facturación</vs-th>
            <vs-th width="1000px">Pagado</vs-th>
            <vs-th width="1000px">Fecha de pago</vs-th>
            <vs-th>Acción</vs-th>
        </template>
        <template slot-scope="{data}">
            <vs-tr :key="indextr" v-for="(tr, indextr) in data">
              <vs-td>
                <a style="padding-left: 10px;" :href="tr.file" target="_blank">
                  Ver
                </a>  
              </vs-td>
              <vs-td>
                <vs-chip transparent :color="tr.canceled_at ? 'danger' : 'success'" class="w-full">
                  {{ tr.canceled_at ? 'Anulada' : 'Activa' }}
                </vs-chip>
              </vs-td>
              <vs-td :class="{'opacity-50': tr.canceled_at}">
                {{ tr.created_at ? dateFormatter(tr.created_at) : '-' }}
              </vs-td>
              <vs-td :class="{'opacity-50': tr.canceled_at}">
                {{ tr.year ? tr.year : '-' }}
              </vs-td>
              <vs-td :class="{'opacity-50': tr.canceled_at}">
                {{ tr.month + 1 ? monthFormatter(tr.month) : '-' }}
              </vs-td>
              <vs-td class="total-currency-column" :id="`total-currency-column-${tr.id}`">
                <vs-select
                  :value="tr.currency"
                  @input="handleCurrencyEdit(tr.id, $event)"
                  
                >
                  <vs-select-item
                    v-for="currency in currencies"
                    :key="currency.value"
                    :value="currency.value"
                    :text="currency.text"
                  />
                  <span slot="no-options">Nada que mostrar.</span>
                </vs-select>
              </vs-td>
              <vs-td class="editable-column total-amount-column" :id="`total-amount-column-${tr.id}`">
                <span class="amount-container" :class="{'opacity-50': tr.canceled_at}"> 
                  {{ tr.total_amount ? stringCurrencyFormatter(tr.total_amount) : '-' }}
                </span>
                <vs-icon 
                  title="Editar monto" 
                  icon="edit" 
                  :color="tr.canceled_at ? 'lightgray' : 'gray'" 
                  class="cursor-pointer" 
                  @click="tr.canceled_at ? null : toggleAmountEdit(tr.id)"
                />
              </vs-td>
              <vs-td>
                <vs-button 
                  :color="tr.invoiced_at ? 'success' : 'danger'" 
                  class="w-full" 
                  @click="toggleInvoiced(tr.id)"
                  size="small"
                  :disabled="tr.canceled_at || tr.month === 0"
                >
                  {{ tr.invoiced_at ? 'Facturado': 'No facturado' }}
                </vs-button>
              </vs-td>
              <vs-td :class="{'opacity-50': tr.canceled_at}">
                {{ tr.invoiced_at ? dateFormatter(tr.invoiced_at) : '-' }}
              </vs-td>
              <vs-td> 
                <vs-button 
                  :color="tr.paid_at ? 'success' : 'danger'" 
                  class="w-full" 
                  @click="toggleCollected(tr.id)"
                  size="small"
                  :disabled="tr.canceled_at || tr.month === 0"
                >
                  {{ tr.paid_at ? 'Pagado': 'No pagado' }}
                </vs-button>
              </vs-td>
              <vs-td :class="{'opacity-50': tr.canceled_at}">
                {{ tr.paid_at ? dateFormatter(tr.paid_at) : '-' }}
              </vs-td>
              <vs-td>
                <div class="flex justify-start gap-2">
                  <vs-button 
                    :color="tr.canceled_at ? 'warning' : 'danger'"
                    :title="tr.canceled_at ? 'Recuperar' : 'Anular'"
                    :type="tr.canceled_at ? 'filled' : 'border'"
                    :icon="tr.canceled_at ? 'restore' : 'not_interested'" 
                    @click="toggleCanceled(tr.id)"
                  />
                  <vs-button 
                    color="primary" 
                    type="filled" 
                    title="Reemplazar" 
                    icon="repeat" 
                    @click="openReplaceOdcInput(tr)"
                    :disabled="tr.canceled_at"
                  />
                  <input 
                    type="file" 
                    :name="`replaceOdcInput-${tr.id}`" 
                    :id="`replaceOdcInput-${tr.id}`" 
                    class="hidden"
                    @change="handleReplaceOdcUpload"
                    accept="application/pdf"
                  >
                </div>
              </vs-td>
            </vs-tr>
        </template>
    </vs-table>
</template>

<style>
.editable-column span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
</style>

<script>
import { dateFormatter, monthFormatter } from '../../../utils/strings';
import { stringCurrencyFormatter } from '../../../utils/strings';
import appConfig from '@/../appConfig.js'

export default {
    name: 'files-table',
    props: {
        purchaseOrders: {
            type: Array,
            default: null
        },
        variant: {
            type: String,
            default: 'billing'
        },
        currencies: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            editingOdcs:[],
            renderPurchaseOrders: [],
            dateFormatter,
            monthFormatter,
            stringCurrencyFormatter
        }
    },
    watch: {
        purchaseOrders: {
            handler(newVal) {
                this.renderPurchaseOrders = [...newVal].sort((a, b) => {
                  if (a.canceled_at && !b.canceled_at) return 1;
                  if (!a.canceled_at && b.canceled_at) return -1;
                  return 0;
                });
            },
            deep: true
        }
    },
    methods: {
      toggleCanceled(id) {
        const currentData = this.purchaseOrders.find(item => item.id === id);
        const data = {
          ...currentData,
          canceled_at: !currentData.canceled_at ? new Date().toISOString() : null
        }
        /* eslint-disable no-unused-vars */
        const {file, ...restData} = data;

        this.updateData(restData);
      },
      toggleInvoiced(id) {
        const currentData = this.purchaseOrders.find(item => item.id === id);
        const data = {
          ...currentData,
          invoiced_at: !currentData.invoiced_at ? new Date().toISOString() : null
        }
        /* eslint-disable no-unused-vars */
        const {file, ...restData} = data;

        this.updateData(restData);
      },
      toggleCollected(id) {
        const currentData = this.purchaseOrders.find(item => item.id === id);
        const data = {
          ...currentData, 
          paid_at: !currentData.paid_at ? new Date().toISOString() : null
        }
        /* eslint-disable no-unused-vars */
        const {file, ...restData} = data;

        this.updateData(restData);
      },

      // Make unique functions or refactor v
      toggleAmountEdit(id) {
        const amountColumn = document.getElementById(`total-amount-column-${id}`);
        // get amount column and icon
        const amountContainer = amountColumn.querySelector('.amount-container');
        const icon = amountColumn.querySelector('i');
        // toggle edit mode
        amountColumn.classList.toggle('edit-mode');
        
        if (amountColumn.classList.contains('edit-mode')) {
          //find current data
          const currentData = this.purchaseOrders.find(item => item.id === id);
          this.editingOdcs.push(currentData);
          const currentAmount = amountContainer.textContent.replace('$', '').trim();
          // create input
          const input = document.createElement('input');
          input.type = 'text';
          input.value = currentAmount;
          input.className = 'vs-inputx vs-input--input normal hasValue amount-container';
          
          // add input event listener
          input.addEventListener('input', (event) => this.handleChangeAmount(event, id));
          // change icon
          icon.textContent = 'save';
          icon.style.color = '#1e88e5';
          // replace amount container with input
          amountContainer.replaceWith(input);
          // focus input
          input.focus();
        } else {
          //update data
          /* eslint-disable no-unused-vars */
          const {file, ...restData} = this.editingOdcs.find(item => item.id === id);
          this.updateData(restData);

          // create span
          const span = document.createElement('span');
          span.classList.add('amount-container');
          span.textContent = stringCurrencyFormatter(amountContainer.value);
          icon.textContent = 'edit';
          icon.style.color = 'gray';
          // replace input with span
          amountContainer.replaceWith(span);

          //remove item data from editingOdcs
          this.editingOdcs = this.editingOdcs.filter(item => item.id !== id);
        }
      },
      handleCurrencyEdit(id, value) {
        const {file, ...currentData} = this.purchaseOrders.find(item => item.id === id);
        const data = {
          ...currentData,
          currency: value
        }

        this.updateData(data);
      },
      handleChangeAmount(event, id) {
        // validate only numbers and decimals
        const value = event.target.value.replace(/[^0-9.,]/g, '');
        event.target.value = value;

        //update data
        this.editingOdcs.find(item => item.id === id).total_amount = value;
      },

      // Make unique functions or refactor ^
      updateData(data) {
        const formData = new FormData();
        formData.append('year', data.year);
        formData.append('month', data.month);
        formData.append('total', data.total_amount);
        formData.append('currency', data.currency);
        formData.append('is_invoiced', Number(Boolean(data.invoiced_at)));
        formData.append('is_paid', Number(Boolean(data.paid_at)));
        formData.append('is_canceled', Number(Boolean(data.canceled_at)));
        formData.append('is_closed', 0);
        
        if (data.file) {
          formData.append('file', data.file);
        }

        return this.$http.post(`${appConfig.apiUrl}/purchase-orders/${data.id}`, formData)
          .then((response) => {
            this.$emit('successFileUpdate', response);
          })
          .catch((error) => {
            console.error(error);
            this.$vs.notify({
              title: 'Error',
              text: error.message,
              color: 'danger'
            });
            throw error;
          });
      },

      openReplaceOdcInput(file) {
        this.selectedFile = file;
        document.getElementById(`replaceOdcInput-${file.id}`).click();
      },
      handleReplaceOdcUpload(event) {
        const file = event.target.files[0];
        if (!file) return;

        if (file.type !== 'application/pdf' || file.size > 2048 * 1000) {
          this.$vs.notify({
              title: 'Error',
              text: 'Solo se permiten archivos PDF menores a 2MB',
              color: 'danger'
          });
          event.target.value = ''; // Reset input
          return;
        }
        const currentData = this.purchaseOrders.find(item => item.id === this.selectedFile.id);
        const data = {
          ...currentData,
          file: file
        }

        this.updateData(data);
      },
    }
}
</script>